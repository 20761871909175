export default function (editor) {
    /* Adds a menu item, which can then be included in any menu via the menu/menubar configuration */
    editor.ui.registry.addMenuItem('drawDivideOnGridShortcode', {
        text: 'Рисование фигур и деление на сетке',
        onAction: function () {
            editor.insertContent(`<p class="mceNonEditable">@@shortcode-draw-divide-on-grid id="${editor.getMaxIdValue(editor.dom.doc.body.innerHTML) + 1}"@@</p>`);
        }
    });
    /* Return the metadata for the help plugin */
    return {
        getMetadata: function () {
            return  {
                name: 'Шорткод: Рисование фигур и деление на сетке',
                url: '#'
            };
        }
    };
}